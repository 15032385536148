

































import Vue, { VueConstructor } from 'vue';
import { mapGetters } from 'vuex';
import { ApiResource } from '@/types';
import MediaPayout from '@/components/media/Payout.vue';

interface VuexBindings {
  detect: (idOrVanity: string) => ApiResource.Business,
  findAccount: (id: string) => ApiResource.Business.Account | undefined,
  listPayouts: (acct: string) => ApiResource.Business.Payout[],
}

export default (Vue as VueConstructor<Vue & VuexBindings>).extend({
  components: {
    MediaPayout,
  },
  props: {
    businessVanity: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      error: null as null | Error,
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      detect: 'business/Detect',
      findAccount: 'business/FindAccount',
      listPayouts: 'business/Payouts/List',
    }),
    BUSINESS(): ApiResource.Business {
      return this.detect(this.businessVanity);
    },
    PAYOUT_ACCOUNT(): ApiResource.Business.Account | undefined {
      return this.findAccount(this.BUSINESS.id);
    },
    list(): ApiResource.Business.Payout[] {
      if (!this.PAYOUT_ACCOUNT) return [];
      return this.listPayouts(this.PAYOUT_ACCOUNT.id)
        // eslint-disable-next-line max-len
        .sort((a: ApiResource.Business.Payout, b: ApiResource.Business.Payout) => b.created_at - a.created_at);
    },
  },
  watch: {
    BUSINESS: {
      immediate: true,
      async handler(to) {
        this.fetchPayouts(to.id);
      },
    },
  },
  methods: {
    async fetchPayouts(BUSINESS_ID: string) {
      this.loading = true;

      await this.$store.dispatch('business/Payouts/List', {
        BUSINESS_ID,
        query: {
          limit: 100,
          offset: 0,
          order: 'created_at',
          direction: 'desc',
        },
      }).catch((e: Error) => { this.error = e; });

      this.loading = false;
    },
  },
  meta() {
    return {
      title: this.$t('title').toString(),
    };
  },
});
