






















































































































































import Vue from 'vue';
import { ApiResource } from '@/types';
import MediaExternalAccount from '@/components/media/ExternalAccount.vue';

export default Vue.extend({
  components: {
    MediaExternalAccount,
  },
  props: {
    payout: {
      type: Object as () => ApiResource.Business.Payout,
      required: true,
    },
  },
  computed: {
    CURRENCY_DIVISOR(): number {
      let d = 100;

      switch (this.payout.currency) {
        case 'EUR':
        default:
          d = 100;
          break;
      }

      return d;
    },
    status(): ApiResource.Business.Payout['status'] {
      if ((this.payout.arrival_at * 1000) < Date.now() && this.payout.status === 'in_transit') return 'paid';
      return this.payout.status;
    },
  },
});
